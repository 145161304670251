import {
  Box,
  Flex,
  FlexProps,
  Heading,
  LicencePlate,
  mdBumps,
  SecondaryButton,
  Typo,
} from '@wrisk/ui-components'
import { DateTime } from 'luxon'
import React, { FunctionComponent } from 'react'

import {
  getAdditionalProfiles,
  getCoreAsset,
  getPolicyholder,
  Quote,
} from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { isDateTimeToday } from '../../../util/date'

const tKey = TKey('components.quote-details')

export interface QuoteDetailsViewProps extends FlexProps {
  onEdit: () => void
  quote: Quote
  startAt?: DateTime
  endAt?: DateTime
}

export const QuoteDetailsView: FunctionComponent<QuoteDetailsViewProps> = ({
  quote,
  startAt,
  endAt,
  onEdit,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const asset = getCoreAsset(quote)
  const policyholder = getPolicyholder(quote)

  const additionalProfiles = getAdditionalProfiles(quote).map((profile) => (
    <Typo key={profile.profileCode} typoSize='sm'>
      {t(tKey('additionalProfile.detail'), { profile })}
    </Typo>
  ))

  const additionalProfileSection = additionalProfiles.length ? (
    <Flex width={1} alignItems='flex-start' justifyContent='flex-start' mt={2}>
      <Flex width={[1 / 2, 1 / 3]}>
        <Typo typoSize='sm' color='bodySecondary'>
          {t(tKey('additionalProfile.title'))}
        </Typo>
      </Flex>
      <Flex width={[1 / 2, 2 / 3]} flexDirection='column' alignItems='flex-start'>
        <Box mb={2}>{additionalProfiles}</Box>
      </Flex>
    </Flex>
  ) : undefined

  const startAtContent = startAt
    ? isDateTimeToday(startAt)
      ? t(tKey('start-at.today'))
      : t(tFormats('datetime.medium'), { value: startAt })
    : undefined

  const startAtSection = startAt && (
    <Flex width={1} alignItems='flex-start' justifyContent='flex-start' mb={2}>
      <Flex width={[1 / 2, 1 / 3]}>
        <Typo typoSize='sm' color='bodySecondary'>
          {t(tKey('start-at.title'))}
        </Typo>
      </Flex>
      <Flex width={[1 / 2, 2 / 3]} alignItems='flex-start'>
        <Typo typoSize='sm'>{startAtContent}</Typo>
      </Flex>
    </Flex>
  )

  const endAtSection = endAt && (
    <Flex width={1} alignItems='flex-start' justifyContent='flex-start' mb={2}>
      <Flex width={[1 / 2, 1 / 3]}>
        <Typo typoSize='sm' color='bodySecondary'>
          {t(tKey('end-at.title'))}
        </Typo>
      </Flex>
      <Flex width={[1 / 2, 2 / 3]} alignItems='flex-start'>
        <Typo typoSize='sm'>{t(tFormats('datetime.medium'), { value: endAt })}</Typo>
      </Flex>
    </Flex>
  )

  const policyholderSection = (
    <Flex alignItems='flex-start' justifyContent='flex-start' mb={2}>
      <Flex width={[1 / 2, 1 / 3]}>
        <Typo typoSize='sm' color='bodySecondary'>
          {t(tKey('policyholder.title'))}
        </Typo>
      </Flex>
      <Flex width={[1 / 2, 2 / 3]} alignItems='flex-start'>
        <Typo typoSize='sm'>{t(tKey('policyholder.detail'), { policyholder })}</Typo>
      </Flex>
    </Flex>
  )

  const coreAssetSection = asset.data.specification && (
    <Flex alignItems='flex-start' justifyContent='flex-start' mb={2}>
      <Flex width={[1 / 2, 1 / 3]}>
        <Typo typoSize='sm' color='bodySecondary'>
          {t(tKey('asset.title'))}
        </Typo>
      </Flex>
      <Flex width={[1 / 2, 2 / 3]} alignItems='flex-start'>
        <Typo typoSize='sm'>
          {t(tKey('asset.detail'), { vehicle: asset.data.specification })}
        </Typo>
      </Flex>
    </Flex>
  )

  const registrationSection = asset.data.vrn ? (
    <Flex alignItems='flex-start' justifyContent='flex-start' mb={2}>
      <Flex width={[1 / 2, 1 / 3]}>
        <Typo typoSize='sm' color='bodySecondary'>
          Registration
        </Typo>
      </Flex>
      <Flex width={[1 / 2, 2 / 3]} alignItems='flex-start'>
        <LicencePlate variant='subtle' size='small' licencePlate={asset.data.vrn} />
      </Flex>
    </Flex>
  ) : null

  const updateDetailsButton = (
    <SecondaryButton layout='small' onClick={onEdit} data-testid='update-details-button'>
      {t(tKey('actions.edit'))}
    </SecondaryButton>
  )

  return (
    <Flex width={1} flexDirection='column' variant='raised' {...props}>
      <Flex width={1} px={mdBumps} pt={mdBumps} pb={1}>
        <Heading variant='h3' typoSize='md'>
          {t(tKey('header'))}
        </Heading>
        {updateDetailsButton}
      </Flex>
      <Box width={1} px={mdBumps} py={5}>
        {registrationSection}
        {coreAssetSection}
        {policyholderSection}
        {additionalProfileSection}
        {startAtSection}
        {endAtSection}
      </Box>
    </Flex>
  )
}
