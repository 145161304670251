import { usePrincipal } from '../../../application/authentication'
import { useProposal } from '../../../application/product/proposal/proposalContext'
import { PolicyChangeType } from '../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../auth'

export const useProposalExcessAdjustment = () => {
  const { apiClient } = usePrincipal()
  const { proposal, setProposal } = useProposal()

  return useApiErrorHandlingAsyncCallback(async ({ excess }: { excess: number }) => {
    setProposal(
      await apiClient.updateProposal(proposal.proposalCode, {
        applyRating: true,
        changes: [
          {
            changeType: PolicyChangeType.SELECT_VOLUNTARY_EXCESS,
            excess,
          },
        ],
      }),
    )
  })
}
