import {
  Flex,
  FlexProps,
  Icon,
  SecondaryButton,
  Typo,
  xsBumps,
  xxsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { canCancel } from '../../../../domain'
import { useNavigateCallback } from '../../../../hooks/navigation'
import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { useProduct } from '../../productContext'
import { usePolicy } from '../policyContext'
import { PolicyPath } from '../PolicyPath'
import { PolicySidebarPricing } from '../pricing'

const tKey = TKey('components.policy-sidebar')

export const PolicySidebar: FunctionComponent<FlexProps> = (props) => {
  const { t } = useWriskTranslation()

  const { product } = useProduct()
  const { policy } = usePolicy()

  const onCancelCallback = useNavigateCallback(PolicyPath.CANCEL)

  const callToAction = canCancel(policy, product.cancellation) && (
    <Flex flexDirection='column' width={1} px={5} pt={5}>
      <SecondaryButton
        width={1}
        onClick={onCancelCallback}
        data-testid='button-cancel-policy'
      >
        {t(tKey('actions.cancel'))}
      </SecondaryButton>
    </Flex>
  )

  const benefits = t(tKey('benefits'), {
    returnObjects: true,
    defaultValue: [],
  }) as string[]

  const benefitsSection = benefits.map((benefit, index) => (
    <Flex justifyContent='flex-start' width={1} my={xxsBumps} key={index}>
      <Icon icon='tick' size='small' mr={xsBumps} />
      <Typo typoSize='sm'>{benefit}</Typo>
    </Flex>
  ))

  return (
    <Flex width={1} flexDirection='column' variant='raised' {...props}>
      <PolicySidebarPricing
        policy={policy}
        product={product}
        borderBottomWidth={1}
        width={1}
      />
      {callToAction}
      {Boolean(benefitsSection.length) && (
        <Flex flexDirection='column' width={1} p={5}>
          {benefitsSection}
        </Flex>
      )}
    </Flex>
  )
}
