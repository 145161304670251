import {
  Header,
  HeaderTitle,
  pageEntranceSpringValues,
  PrimaryButton,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'

import { getCurrentQuote, Profile, Proposal } from '../../../../../../domain'
import { useRemoveAdditionalProfileProposalAdjustment } from '../../../../../../hooks/adjustments/proposal'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { AdditionalProfileDisclosureConfig } from '../../../../../../state/configuration'
import { AdditionalProfileView } from '../../../../../organisms/quote'
import { useProposal } from '../../../proposalContext'
import { AdditionalProfilePath } from '../path'

export interface AdditionalProfileSummaryProps {
  section: AdditionalProfileDisclosureConfig
  isLastSection: boolean
  onComplete: (proposal: Proposal) => void
}

const tKey = TKey('pages.additional-profile-summary')

export const AdditionalProfileSummary: FunctionComponent<
  AdditionalProfileSummaryProps
> = ({ section, isLastSection, onComplete }) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()

  const spring = useSpring(pageEntranceSpringValues)
  const { proposal } = useProposal()

  const quote = getCurrentQuote(proposal)
  const additionalProfiles = quote.insuredProfiles.filter((it) => !it.policyholder)

  const onUpdateCallback = useCallback(
    (profile: Profile) => {
      navigate(generatePath(AdditionalProfilePath.Profile, profile))
    },
    [navigate],
  )
  const onCompleteCallback = useCallback(
    () => onComplete(proposal),
    [onComplete, proposal],
  )
  const onRemoveCallback = useRemoveAdditionalProfileProposalAdjustment(isLastSection)
  const onAddCallback = useCallback(() => {
    navigate(generatePath(AdditionalProfilePath.NewProfile))
  }, [navigate])

  return (
    <animated.div style={spring}>
      <Header>
        <HeaderTitle>{t(tKey('header'))}</HeaderTitle>
      </Header>

      <AdditionalProfileView
        insuredProfiles={quote.insuredProfiles}
        onUpdate={onUpdateCallback}
        onAdd={onAddCallback}
        onRemove={onRemoveCallback.execute}
        loading={onRemoveCallback.loading}
        maxAdditionalProfiles={section.maxAdditionalProfiles}
        mb={xxlBumps}
      />

      <PrimaryButton
        type='submit'
        layout='fixed'
        mb={xxlBumps}
        onClick={onCompleteCallback}
        data-testid='button-confirm'
      >
        {additionalProfiles.length
          ? t(tKey('actions.continue'))
          : t(tKey('actions.without'))}
      </PrimaryButton>
    </animated.div>
  )
}
