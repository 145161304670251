import axios from 'axios'

export interface SessionConfig {
  hostname: string
  brand: string // TODO (MK): Single place for this
}

export interface SessionClient {
  getToken: () => Promise<string>
  retrieveToken: (request: RetrieveRequest & { brand: string }) => Promise<string>
}

export interface RetrieveRequest {
  externalReference: string
  dateOfBirth: string
  postalCode: string
}

export const createSessionClient = ({ hostname }: SessionConfig): SessionClient => {
  const instance = axios.create({
    baseURL: `${hostname}/api`,
  })

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  instance.defaults.headers.common.Accept = 'application/json'
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  instance.defaults.headers.post['Content-Type'] = 'application/json'

  return {
    getToken: async () => {
      const response = await instance.post<{ accessToken: string }>('/session')
      return response.data.accessToken
    },
    retrieveToken: async (request: RetrieveRequest & { brand: string }) => {
      const response = await instance.post('/session/back-book', request)
      return response.data.accessToken
    },
  }
}
