import { getCoreAsset, getPolicyholder, PolicyVersion } from '../../../../domain'
import { ChangeConfig, ChangeType } from '../../../../state/configuration'
import { toData } from '../../helpers'

export const toPolicyData =
  (policyVersion: PolicyVersion) => (adjustment: ChangeConfig) => {
    const { quote } = policyVersion
    const { data: profile } = getPolicyholder(quote)
    const { data: asset } = getCoreAsset(quote)

    switch (adjustment.changeType) {
      case ChangeType.PROFILE_DISCLOSURE:
      case ChangeType.PROFILE_ATTRIBUTE:
        return toData(profile, adjustment.update)
      case ChangeType.ASSET_DISCLOSURE:
      case ChangeType.ASSET_ATTRIBUTE:
        return toData(asset, adjustment.update)
      case ChangeType.QUOTE_DISCLOSURE:
      case ChangeType.QUOTE_ATTRIBUTE:
        return toData(quote.data, adjustment.update)
      case ChangeType.START_DATE_CHANGE:
        return policyVersion.startedAt
    }
  }
