import { Profile } from '../../../../domain'
import { ChangeConfig, ChangeType } from '../../../../state/configuration'
import { toData } from '../../helpers'

export const toAdditionalProfilePolicyData =
  (profile: Profile | undefined) => (adjustment: ChangeConfig) => {
    switch (adjustment.changeType) {
      case ChangeType.PROFILE_DISCLOSURE:
      case ChangeType.PROFILE_ATTRIBUTE:
        return toData(profile?.data ?? {}, adjustment.update)
      default:
        throw new Error(
          `AdjustmentChangeType ${adjustment.changeType} not supported by this`,
        )
    }
  }
