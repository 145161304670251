import {
  Box,
  Section,
  SectionContent,
  SectionTitle,
  smBumps,
  Typo,
  xlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { getCurrentQuote, getPolicyholder } from '../../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { Page, useUpButton } from '../../../../infrastructure/routing'
import { getProduct, ManagementType, useConfig } from '../../../../state/configuration'
import { toDateTime } from '../../../../util/date'
import { ReferralReasons } from '../../../organisms/quote'
import { FullPage } from '../../../templates'
import { useProduct } from '../../productContext'
import { useProposal } from '../proposalContext'
import { AdditionalProfileManagement } from './components/AdditionalProfileManagement'
import { QuoteManagement } from './components/QuoteManagement'

const tKey = TKey('pages.renewal-referral')

export const RenewalReferralPage: FunctionComponent<{ home: Page }> = ({ home }) => {
  const { t } = useWriskTranslation()

  const upButton = useUpButton(home)

  const { proposal } = useProposal()
  const { scheme } = useProduct()
  const {
    renewal: { sections },
  } = useConfig(getProduct(scheme.schemeCode))

  const quote = getCurrentQuote(proposal)
  const profile = getPolicyholder(quote)

  const subheader = (
    <Trans
      t={t}
      i18nKey={tKey('subheader')}
      values={{
        startAt: t(tFormats('datetime.medium'), { value: toDateTime(proposal.startAt) }),
      }}
    />
  )

  const configuredSections = sections.map((it) => {
    const content = (() => {
      switch (it.type) {
        case ManagementType.ADDITIONAL_PROFILE:
          return <AdditionalProfileManagement config={it} quote={quote} />
        default:
          return <QuoteManagement config={it} quote={quote} />
      }
    })()

    return (
      <Section key={it.id} width={1} mb={xlBumps}>
        <SectionTitle>{t(tKey('sections', it.id))}</SectionTitle>
        <SectionContent>{content}</SectionContent>
      </Section>
    )
  })

  return (
    <FullPage
      header={t(tKey('header'), { profile })}
      subheader={subheader}
      upButton={upButton}
    >
      <ReferralReasons quote={quote} mb={smBumps} width={1} />
      <Box width={1} mb={xlBumps}>
        <Typo typoSize='sm'>{t(tKey('check-details'))}</Typo>
      </Box>
      {configuredSections}
    </FullPage>
  )
}
