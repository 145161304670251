import React, { FunctionComponent } from 'react'
import { Route, Routes } from 'react-router-dom'

import { BillingManager } from '../billing'
import { CancellationConfirmationPage } from '../cancellation/CancellationConfirmationPage'
import { PolicyTabs } from '../configuration'
import { PolicyPath } from '../PolicyPath'

export const PolicyEndedRoutes: FunctionComponent = () => (
  <BillingManager>
    <Routes>
      <Route
        key='cancellation-confirmation'
        path={PolicyPath.CANCEL_CONFIRMATION}
        element={<CancellationConfirmationPage />}
      />
      <Route path='*' element={<PolicyTabs />} />
    </Routes>
  </BillingManager>
)
