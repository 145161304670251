import { Notification, NotificationProps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { getPolicyToEndAt, isPolicyTerminated, Policy } from '../../../../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'

const tKey = TKey('pages.policy-configuration')

export const PolicyCancellationNotification: FunctionComponent<
  { policy: Policy; basePath: string } & NotificationProps
> = ({ policy, ...props }) => {
  const { t } = useWriskTranslation()

  if (isPolicyTerminated(policy)) {
    return (
      <Notification type='standalone' variant='info' {...props}>
        <Typo>
          <Trans
            t={t}
            i18nKey={tKey('cancellation-message')}
            values={{
              endedAt: t(tFormats('datetime.medium'), {
                value: getPolicyToEndAt(policy),
              }),
            }}
          />
        </Typo>
      </Notification>
    )
  }

  return null
}
