import {
  Box,
  BoxProps,
  InputWrapper,
  SingleSelect,
  TextInput,
  xsBumps,
} from '@wrisk/ui-components'
import { isEmpty, isNil, negate } from 'lodash'
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  tEntries,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export interface CancellationReasonInputProps extends BoxProps {
  name?: string
  onChange: (value: string | null) => void
  value: string | null
  reasons: string[]
}

export const CancellationReasonInput: FunctionComponent<CancellationReasonInputProps> = ({
  name,
  onChange,
  reasons,
  value,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const [[reason, other], setCancellationReason] = useState(value?.split('|') ?? [])

  const cancellationReasons = useMemo(
    () =>
      reasons.reduce(
        (acc, reason) => ({
          ...acc,
          [reason]: t(tEntries('cancellationReasons', reason)),
        }),
        {},
      ),
    [reasons, t],
  )

  useEffect(() => {
    reason === 'Other'
      ? isEmpty(other)
        ? onChange(null)
        : onChange([reason, other].join('|'))
      : onChange(reason)
  }, [reason, other, onChange])

  const onReasonChange = useCallback(
    (reason: string) => setCancellationReason(([, prevOther]) => [reason, prevOther]),
    [],
  )

  const onOtherChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) =>
      setCancellationReason(([prevReason]) => [prevReason, target.value]),
    [],
  )

  return (
    <Box id={name} width={1} {...props}>
      <SingleSelect
        name={[name, 'reason'].filter(negate(isNil)).join('-')}
        onChange={onReasonChange}
        value={reason}
        options={cancellationReasons}
      />
      {reason === 'Other' && (
        <Box width={[1, 1, 4 / 5]} mt={xsBumps}>
          <InputWrapper>
            <TextInput
              name={[name, 'other'].filter(negate(isNil)).join('-')}
              type='text'
              variant='ghost'
              width={1}
              placeholder='Please share more details'
              value={other ?? ''}
              onChange={onOtherChange}
            />
          </InputWrapper>
        </Box>
      )}
    </Box>
  )
}
