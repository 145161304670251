import { isNil } from 'lodash'
import { DateTime } from 'luxon'

import {
  CancellationConfig,
  CancellationType,
  CoolingOffStart,
} from '../state/configuration'
import { hasPolicyEnded, isPolicyTerminated } from './policy'
import { Policy } from './types'

export const isCancellationReasonInvalid = (
  reasons: string[] | undefined,
  reason: string | null,
): boolean => !isNil(reasons) && isNil(reason)

export const canCancel = (
  policy: Policy,
  config: CancellationConfig | undefined,
): config is CancellationConfig => {
  const isPolicyInCorrectState = !isPolicyTerminated(policy) && !hasPolicyEnded(policy)

  const doesConfigAllowCancellation = (() => {
    switch (config?.type) {
      case CancellationType.CoolingOff: {
        const dateFrom = DateTime.fromISO(
          config.startAt === CoolingOffStart.Inception
            ? policy.policyDetail.inceptedAt
            : policy.policyDetail.createdAt,
        )

        const { days = 0 } = DateTime.local().diff(dateFrom, 'days').toObject()

        return days < config.days
      }
      case CancellationType.Standard:
        return true
      default:
        return false
    }
  })()

  return isPolicyInCorrectState && doesConfigAllowCancellation
}
