import { isNil } from 'lodash'
import { default as React, FunctionComponent } from 'react'

import { tDefaults, tEntries } from '../../../infrastructure/internationalisation'
import { Formatter } from '../types'

export const RadioFormatter: FunctionComponent<Formatter<string>> = ({
  value,
  t,
  input: { name },
}) => <>{isNil(value) ? t(tDefaults('inputs.no-answer')) : t(tEntries(name, value))}</>
