import { Illus, LinkAnchor } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { useNavigateCallback } from '../../../../hooks/navigation'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { toDateTime } from '../../../../util/date'
import { AppPath } from '../../../AppPath'
import { usePrincipal } from '../../../authentication'
import { Feedback } from '../../../organisms/feedback/Feedback'
import { FullPage } from '../../../templates'
import { usePolicy } from '../policyContext'

const tKey = TKey('pages.cancel-confirmation')

export const CancellationConfirmationPage: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  const { policy } = usePolicy()
  const { isAdmin } = usePrincipal()

  const onContinue = useNavigateCallback(AppPath.HOME)

  const subheader = (
    <Trans
      t={t}
      i18nKey={tKey('subheader')}
      values={{
        terminatedAt: t(tFormats('datetime.medium'), {
          value: toDateTime(policy.policyDetail.terminatedAt),
        }),
      }}
    />
  )

  return (
    <FullPage
      pageId='cancel-confirmation'
      header={t(tKey('header'))}
      subheader={subheader}
      upperHeader={
        <Illus alt='CancelSuccess' data-testid='cancel-success' illus='cancelSuccess' />
      }
    >
      <LinkAnchor onClick={onContinue} variant='standalone'>
        {t(tKey('actions.view-dashboard'))}
      </LinkAnchor>
      {!isAdmin && <Feedback type='cancellation' tags={{ policyId: policy.policyId }} />}
    </FullPage>
  )
}
