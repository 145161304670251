/* eslint-disable @typescript-eslint/no-explicit-any */
import { AdjustmentInputConfig, InputConfig, ManagementType, StateConfig } from './types'

const getInputConfig =
  (config: any) =>
  (name: string): InputConfig => {
    const input = config.inputs[name]
    if (!input) throw new Error(`No input configured for ${name}`)

    return input
  }

const getAdjustmentInputConfig =
  (config: any) =>
  (name: string): AdjustmentInputConfig => ({
    ...getInputConfig(config)(name),
    adjustment: config.adjustments[name],
  })

export const mapJsonToConfiguration = (config: any): StateConfig => {
  const getAdjustmentInput = getAdjustmentInputConfig(config)
  const getInput = getInputConfig(config)

  return {
    ...config,
    account: config.account?.map(getInput) ?? [],
    productConfig: Object.entries(config.productConfig).reduce(
      (acc, [schemeCode, productConfig]: [string, any]) => ({
        ...acc,
        [schemeCode]: {
          ...productConfig,
          renewal: {
            ...productConfig.renewal,
            sections:
              productConfig.renewal?.sections?.map((it: any) => ({
                ...it,
                inputs: it.inputs?.map(getAdjustmentInput) ?? [],
              })) ?? [],
          },
          management:
            productConfig.management?.map((it: any) => ({
              ...it,
              inputs: it.inputs?.map(getAdjustmentInput) ?? [],
            })) ?? [],
          summary:
            productConfig.summary?.map((it: any) => ({
              ...it,
              inputs: it.inputs?.map(getAdjustmentInput) ?? [],
            })) ?? [],
          disclosures:
            productConfig.disclosures?.map((it: any) => {
              switch (it.type) {
                case ManagementType.PROMOTION:
                  return {
                    ...it,
                    promotions: Object.entries(it.promotions).reduce(
                      (acc, [promoCode, promotion]: [string, any]) => ({
                        ...acc,
                        [promoCode]: {
                          ...promotion,
                          inputs: promotion.inputs?.map(getAdjustmentInput) ?? [],
                        },
                      }),
                      {},
                    ),
                  }
                default:
                  return {
                    ...it,
                    inputs: it.inputs?.map(getAdjustmentInput) ?? [],
                  }
              }
            }) ?? [],
          proposalConfirmation: productConfig?.proposalConfirmation
            ? {
                ...(productConfig?.proposalConfirmation ?? {}),
                inputs:
                  productConfig.proposalConfirmation?.inputs.map(getAdjustmentInput) ??
                  [],
              }
            : undefined,
          accountSetup: productConfig.accountSetup?.map(getAdjustmentInput) ?? [],
          expiredProposal: productConfig.expiredProposal?.map(getAdjustmentInput) ?? [],
          beforeWeBegin: productConfig.beforeWeBegin?.map(getAdjustmentInput) ?? [],
        },
      }),
      {},
    ),
  }
}
