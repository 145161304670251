import {
  lgBumps,
  MainSection,
  NotificationContainer,
  pageEntranceSpringValues,
  SideSection,
  StickySidebar,
  WideContainer,
  xlBumps,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { animated, useSpring } from 'react-spring'

import { getPolicyVersion } from '../../../../../../domain'
import { useCurrentPath } from '../../../../../../infrastructure/routing'
import {
  getExposuresContentBaseUrl,
  useConfig,
} from '../../../../../../state/configuration'
import { RenewalBanner } from '../../../../../homePage/organisms/RenewalBanner'
import { CoverSection } from '../../../../../organisms/covers'
import { PolicyDetailsView } from '../../../../../organisms/policy'
import { ProductFaqSection } from '../../../../../organisms/product'
import { PolicyPromotionBanner } from '../../../../../organisms/promotion'
import { useProduct } from '../../../../productContext'
import { PolicyExcessSection } from '../../../excess'
import { usePolicy } from '../../../policyContext'
import { PolicySidebar } from '../../PolicySidebar'
import { AddOnsSection } from '../components/AddOnsSection'
import { PolicyCancellationNotification } from '../components/PolicyCancellationNotification'

export const PolicyDetailsTab: FunctionComponent = () => {
  const path = useCurrentPath()

  const { scheme } = useProduct()
  const { policy } = usePolicy()
  const { quote } = getPolicyVersion(policy)

  const spring = useSpring(pageEntranceSpringValues)

  const contentBaseUrl = useConfig(getExposuresContentBaseUrl(scheme.schemeCode))

  return (
    <animated.div style={spring}>
      <WideContainer>
        <NotificationContainer mb={xlBumps}>
          <RenewalBanner type='standalone' />
          <PolicyCancellationNotification policy={policy} basePath={path} />
          <PolicyPromotionBanner />
        </NotificationContainer>
      </WideContainer>
      <WideContainer variant='sidebar'>
        <StickySidebar variant='offset'>
          <SideSection>
            <PolicySidebar key='desktop' />
          </SideSection>
        </StickySidebar>
        <MainSection>
          <PolicySidebar key='mobile' displayOn='mobile' mb={lgBumps} />
          <PolicyDetailsView mb={xxlBumps} />
          <AddOnsSection quote={quote} contentBaseUrl={contentBaseUrl} mb={xxlBumps} />
          <CoverSection quote={quote} contentBaseUrl={contentBaseUrl} mb={xxlBumps} />
          <PolicyExcessSection mb={xxlBumps} />
          <ProductFaqSection />
        </MainSection>
      </WideContainer>
    </animated.div>
  )
}
