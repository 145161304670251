import {
  Box,
  Flex,
  mdBumps,
  Notification,
  NotificationProps,
  PrimaryButton,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import {
  getCurrentQuote,
  getPolicyToEndAt,
  getPolicyUpsell,
  Policy,
  Proposal,
  ProposalStatus,
} from '../../../domain'
import { useQuotePremiums } from '../../../hooks/premiums'
import { useContinueProposalCallback } from '../../../hooks/proposal'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { usePolicy } from '../../product/policy/policyContext'
import { useProduct } from '../../product/productContext'

const tKey = TKey('components.upsell-proposal-banner')

const Content: FunctionComponent<
  { linkedProposal: Proposal; policy: Policy } & NotificationProps
> = ({ linkedProposal, policy, ...props }) => {
  const { product } = useProduct()

  const { t } = useWriskTranslation()

  const quote = getCurrentQuote(linkedProposal)

  const { totals } = useQuotePremiums(quote, product)

  const onContinueProposalCallback = useContinueProposalCallback(linkedProposal)

  return (
    <Notification variant='info' flexWrap={['wrap', 'wrap', 'nowrap']} {...props}>
      <Flex
        justifyContent='flex-start'
        mr={[0, 0, 4]}
        mb={[4, 4, 0]}
        width={[1, 1, 3 / 5]}
      >
        <Typo>
          <Trans
            t={t}
            i18nKey={tKey(linkedProposal.schemeCode, 'content')}
            values={{
              endingAt: t(tFormats('datetime.medium'), {
                value: getPolicyToEndAt(policy),
              }),
            }}
          />
        </Typo>
      </Flex>
      <Box width={[1, 1, 2 / 5]} pl={[0, 0, ...mdBumps.slice(2)]}>
        <PrimaryButton onClick={onContinueProposalCallback} whiteSpace='nowrap' width={1}>
          {linkedProposal.status === ProposalStatus.QUOTE ? (
            <>
              {t(tKey(linkedProposal.schemeCode, 'actions.view'))}
              <Typo
                typoSize='sm'
                fontWeight='normal'
                textTransform='lowercase'
                whiteSpace='nowrap'
              >
                <Trans
                  t={t}
                  i18nKey={tKey(linkedProposal.schemeCode, 'actions.priced')}
                  values={{
                    premium: t(tFormats('currency.long'), {
                      amount: totals.discounted,
                    }),
                  }}
                />
              </Typo>
            </>
          ) : (
            t(tKey(linkedProposal.schemeCode, 'actions.complete'))
          )}
        </PrimaryButton>
      </Box>
    </Notification>
  )
}

export const UpsellProposalBanner: FunctionComponent<NotificationProps> = (props) => {
  const { policy } = usePolicy()

  const upsellProposal = getPolicyUpsell(policy)

  return upsellProposal ? (
    <Content policy={policy} linkedProposal={upsellProposal} {...props} />
  ) : null
}
