import React, { FunctionComponent } from 'react'

import { Page } from '../../../../infrastructure/routing'
import { CancellationConfig, CancellationType } from '../../../../state/configuration'
import { CancellationCoolingOffPage } from './CancellationCoolingOffPage'
import { CancellationStandardPage } from './CancellationStandardPage'

export interface CancellationPageProps {
  parent: Page
  config: CancellationConfig
}

export const CancellationPage: FunctionComponent<CancellationPageProps> = ({
  parent,
  config,
}) => {
  switch (config.type) {
    case CancellationType.Standard:
      return <CancellationStandardPage config={config} parent={parent} />
    case CancellationType.CoolingOff:
      return <CancellationCoolingOffPage config={config} parent={parent} />
  }
}
