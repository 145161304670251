import deepEqual from 'deep-equal'
import jp from 'jsonpath'

import { Data, JsonUpdate } from '../../domain'
import {
  AdjustmentInputConfig,
  AdjustmentUpdate,
  AssetAttributeChangeConfig,
  AssetChangeConfig,
  ProfileAttributeChangeConfig,
  ProfileChangeConfig,
  QuoteAttributeChangeConfig,
  QuoteChangeConfig,
} from '../../state/configuration'
import { isString } from '../../util/string'

export const toData = (data: Data, update: AdjustmentUpdate): Data =>
  isString(update)
    ? jp.value(data, update)
    : update.reduce(
        (acc, update) => ({
          ...acc,
          [update.id]: jp.value(data, update.path),
        }),
        {},
      )

export const toUpdates = (
  {
    adjustment,
    ...input
  }: AdjustmentInputConfig<
    | ProfileChangeConfig
    | AssetChangeConfig
    | QuoteChangeConfig
    | ProfileAttributeChangeConfig
    | AssetAttributeChangeConfig
    | QuoteAttributeChangeConfig
  >,
  data: Data,
): JsonUpdate[] => {
  return isString(adjustment.update)
    ? [
        {
          path: adjustment.update,
          value: data[input.name],
        },
      ]
    : adjustment.update.map((it) => ({
        path: it.path,
        value: data[input.name][it.id],
      }))
}

export const hasUpdate =
  (existingData: Data, data: Data) => (input: AdjustmentInputConfig) =>
    !deepEqual(existingData[input.name], data[input.name])
