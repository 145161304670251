import {
  Box,
  Flex,
  Icon,
  mdBumps,
  Notification,
  NotificationProps,
  PrimaryButton,
  smBumps,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import {
  getCurrentQuote,
  getPolicyToEndAt,
  isPolicyNotRenewable,
  Policy,
  RenewalPolicy,
} from '../../../../domain'
import { useErrorHandlingAsyncCallback } from '../../../../hooks/errors'
import { useQuotePremiums } from '../../../../hooks/premiums'
import { useContinueProposalCallback } from '../../../../hooks/proposal'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { usePrincipal } from '../../../authentication'
import { useProduct } from '../../../product/productContext'

const tKey = TKey('components.renewal-banner')

interface RenewalQuoteBannerProps extends NotificationProps {
  policy: RenewalPolicy
  onChange: (policy: Policy) => void
}

export const RenewalQuoteBanner: FunctionComponent<RenewalQuoteBannerProps> = ({
  policy,
  ...props
}) => {
  const RenewalBanner = isPolicyNotRenewable(policy)
    ? RenewalOptedOutBanner
    : RenewalOptedInBanner
  return <RenewalBanner policy={policy} {...props} />
}

const RenewalOptedOutBanner: FunctionComponent<RenewalQuoteBannerProps> = ({
  policy,
  onChange,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const { product } = useProduct()
  const { apiClient } = usePrincipal()

  const onOptInCallback = useErrorHandlingAsyncCallback(async () => {
    onChange(await apiClient.clearPolicyRenewalStop(policy.policyId))
  })

  const renewalQuote = getCurrentQuote(policy.renewalProposal)

  const { totals } = useQuotePremiums(renewalQuote, product)

  return (
    <Notification variant='info' flexWrap={['wrap', 'wrap', 'nowrap']} {...props}>
      <Flex
        justifyContent='flex-start'
        mb={[4, 4, 0]}
        mr={[0, 0, 4]}
        width={[1, 1, 3 / 5]}
      >
        <Icon icon='info' size='small' mr={smBumps} display={['none', 'none', 'block']} />
        <Typo>
          <Trans
            t={t}
            i18nKey={tKey('status.optedOut')}
            values={{
              amount: t(tFormats('currency.long'), { amount: totals.discounted }),
            }}
          />
        </Typo>
      </Flex>
      <Box width={[1, 1, 2 / 5]} pl={[0, 0, ...mdBumps.slice(2)]}>
        <PrimaryButton
          width={1}
          onClick={onOptInCallback.execute}
          loading={onOptInCallback.loading}
        >
          {t(tKey('actions.optIn'))}
        </PrimaryButton>
      </Box>
    </Notification>
  )
}

const RenewalOptedInBanner: FunctionComponent<RenewalQuoteBannerProps> = ({
  policy,
  onChange,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const onContinueProposalCallback = useContinueProposalCallback(policy.renewalProposal)

  return (
    <Notification variant='info' flexWrap={['wrap', 'wrap', 'nowrap']} {...props}>
      <Flex
        justifyContent='flex-start'
        mb={[4, 4, 0]}
        mr={[0, 0, 4]}
        width={[1, 1, 3 / 5]}
      >
        <Icon icon='info' size='small' mr={smBumps} display={['none', 'none', 'block']} />
        <Typo>
          <Trans
            t={t}
            i18nKey={tKey('status.optedIn')}
            values={{
              endingAt: t(tFormats('datetime.medium'), {
                value: getPolicyToEndAt(policy),
              }),
            }}
          />
        </Typo>
      </Flex>
      <Box width={[1, 1, 2 / 5]} pl={[0, 0, ...mdBumps.slice(2)]}>
        <PrimaryButton width={1} onClick={onContinueProposalCallback}>
          {t(tKey('actions.view'))}
        </PrimaryButton>
      </Box>
    </Notification>
  )
}
