import React, { FunctionComponent } from 'react'
import { generatePath, Route, Routes } from 'react-router-dom'

import { Page } from '../../../../infrastructure/routing'
import { getProduct, ManagementType, useConfig } from '../../../../state/configuration'
import { AppPath } from '../../../AppPath'
import { useProduct } from '../../productContext'
import { RenewalAdditionalProfileRouter } from './RenewalAdditionalProfileRouter'
import { RenewalDisclosurePage } from './RenewalDisclosurePage'
import { RenewalPath } from './RenewalPath'

export const RenewalDisclosureRouter: FunctionComponent<{ parent: Page }> = ({
  parent,
}) => {
  const { scheme } = useProduct()
  const {
    renewal: { sections },
  } = useConfig(getProduct(scheme.schemeCode))

  const routes = sections.map((it) => {
    const element = (() => {
      switch (it.type) {
        case ManagementType.ADDITIONAL_PROFILE:
          return <RenewalAdditionalProfileRouter config={it} parent={parent} />
        default:
          return <RenewalDisclosurePage config={it} parent={parent} />
      }
    })()

    return (
      <Route
        key={it.id}
        path={generatePath(RenewalPath.SECTION, { sectionId: it.id }) + AppPath.WILDCARD}
        element={element}
      />
    )
  })

  return <Routes>{routes}</Routes>
}
