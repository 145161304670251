import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { canCancel } from '../../../../domain'
import { Page } from '../../../../infrastructure/routing'
import { useProduct } from '../../productContext'
import { usePolicy } from '../policyContext'
import { CancellationConfirmPage } from './CancellationConfirmPage'
import { CancellationPage } from './CancellationPage'
import { CancellationPath } from './CancellationPath'

export const CancellationRouter: FunctionComponent<{ parent: Page }> = ({ parent }) => {
  const { policy } = usePolicy()
  const {
    product: { cancellation },
  } = useProduct()

  return canCancel(policy, cancellation) ? (
    <Routes>
      <Route
        key='cancel'
        index
        element={<CancellationPage parent={parent} config={cancellation} />}
      />
      <Route
        key='confirm'
        path={CancellationPath.CONFIRM}
        element={<CancellationConfirmPage parent={parent} />}
      />
      <Route path='*' element={<Navigate to={parent.url} />} />
    </Routes>
  ) : (
    <Navigate to={parent.url} />
  )
}
