import React, { FunctionComponent, PropsWithChildren } from 'react'

import { useApiErrorHandlingAsync } from '../../../hooks/auth'
import { Principal, usePrincipal } from '../PrincipalProvider'
import { PolicyholderProvider } from './policyholderContext'

export interface PolicyholderManagerProps extends PropsWithChildren {
  principal: Principal
}

export const PolicyholderManager: FunctionComponent<PolicyholderManagerProps> = ({
  principal,
  children,
}) => {
  const { apiClient } = usePrincipal()

  const policyholderAsync = useApiErrorHandlingAsync(apiClient.getPolicyholder, [
    principal,
  ])

  return (
    <PolicyholderProvider policyholder={policyholderAsync.result}>
      {children}
    </PolicyholderProvider>
  )
}
