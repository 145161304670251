import { DateTime } from 'luxon'

import { Policy, PolicyStatus, PolicyVersion, ProposalStatus } from './types'

export const getPolicyVersion = (policy: Policy) => policy.currentPolicyVersion

export const hasPolicyStarted = (policy: Policy): boolean =>
  ![PolicyStatus.PENDING].includes(policy.policyDetail.policyStatus)

export const hasPolicyEnded = (policy: Policy): boolean =>
  [
    PolicyStatus.CANCELLED,
    PolicyStatus.VOIDED,
    PolicyStatus.LAPSED,
    PolicyStatus.RENEWED,
  ].includes(policy.policyDetail.policyStatus)

export const isPolicyTerminated = (policy: Policy) =>
  Boolean(policy.policyDetail.terminatedAt)

export const getExpiryDate = (policy: Policy): DateTime =>
  DateTime.fromISO(policy.policyDetail.expiredAt)

export const getInceptedDate = (policy: Policy): DateTime =>
  DateTime.fromISO(policy.policyDetail.inceptedAt)

export const getFirstInceptedDate = (policy: Policy): DateTime =>
  DateTime.fromISO(policy.policyDetail.firstInceptedAt)

export const isInceptionDate = (policy: Policy, date: string) =>
  getInceptedDate(policy).startOf('day').toISO() ===
  DateTime.fromISO(date).startOf('day').toISO()

export const getPolicyUpsell = (policy: Policy) =>
  [
    ProposalStatus.QUOTE,
    ProposalStatus.QUOTE_EXPIRED,
    ProposalStatus.INSUFFICIENT_ACCURACY,
  ].includes(policy.upsellProposal?.status as ProposalStatus)
    ? policy.upsellProposal
    : undefined

export const getPolicyToEndAt = (policy: Policy): DateTime | undefined => {
  const endedAt = (() => {
    switch (policy.policyDetail.policyStatus) {
      case PolicyStatus.LAPSED:
        return policy.policyDetail.expiredAt
      case PolicyStatus.CANCELLED:
        return policy.policyDetail.terminatedAt
      case PolicyStatus.VOIDED:
        return policy.policyDetail.inceptedAt
      default:
        return policy.policyDetail.terminatedAt ?? policy.policyDetail.expiredAt
    }
  })()

  return endedAt ? DateTime.fromISO(endedAt) : undefined
}

export const getCurrentPolicyVersion = ([version]: PolicyVersion[]) => {
  if (!version) {
    throw new Error('There are no policy versions')
  }

  return version
}
