import { usePrincipal } from '../../application/authentication'
import { usePolicy } from '../../application/product/policy/policyContext'
import { useProduct } from '../../application/product/productContext'
import { canCancel } from '../../domain'
import { useApiErrorHandlingAsyncCallback } from '../auth'

export const useCancelPolicyAdjustment = (
  cancellationReason: string | null,
  effectiveAt: string | undefined,
  onConfirm: () => void,
) => {
  const { apiClient } = usePrincipal()
  const { policy, setPolicy } = usePolicy()
  const { product } = useProduct()

  return useApiErrorHandlingAsyncCallback(async () => {
    if (!canCancel(policy, product.cancellation)) {
      throw new Error(`Cancellation attempted but not allowed for ${policy.policyId}`)
    }

    const { adjustmentId, confirmed } = await apiClient.createPolicyAdjustment({
      adjustmentType: 'CAN',
      policyId: policy.policyId,
      reason: cancellationReason ?? undefined,
      changes: [],
      effectiveAt,
    })

    if (!confirmed) {
      await apiClient.confirmPolicyAdjustment(adjustmentId)
    }

    setPolicy(await apiClient.getPolicy(policy.policyId))
    onConfirm?.()
  })
}
