import {
  Box,
  Flex,
  LinkAnchor,
  Section,
  SectionContent,
  SectionTitle,
  smBumps,
  Typo,
  useModal,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'

import {
  getCoreAsset,
  getCurrentQuote,
  getPolicyholder,
  isRenewalProposal,
  RenewalProposal,
} from '../../../../domain'
import { useProposalExcessAdjustment } from '../../../../hooks/adjustments/proposal'
import { useIncludedCovers, useOptionalCovers } from '../../../../hooks/covers'
import { useNavigateCallback } from '../../../../hooks/navigation'
import {
  tDefaults,
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { Page, useUpButton } from '../../../../infrastructure/routing'
import { getProduct, ManagementType, useConfig } from '../../../../state/configuration'
import { toDateTime } from '../../../../util/date'
import { AppPath } from '../../../AppPath'
import {
  IncludedCoversExpandable,
  OptionalCoversExpandable,
} from '../../../organisms/covers'
import {
  ExcessExpandable,
  ExcessModalContent,
  ExclusionsExpandable,
} from '../../../organisms/excess'
import { ProductDocumentsSection } from '../../../organisms/product'
import { FullPage } from '../../../templates'
import { useProduct } from '../../productContext'
import { CoverDurationSection } from '../configuration/ProposalSummaryPage/components/CoverDurationSection'
import { ProposalAdjustmentPricing } from '../pricing'
import { useProposal } from '../proposalContext'
import { ProposalPath } from '../ProposalPath'
import { AdditionalProfileManagement } from './components/AdditionalProfileManagement'
import { QuoteManagement } from './components/QuoteManagement'

const tKey = TKey('pages.renewal-quote')

interface ContentProps {
  home: Page
  proposal: RenewalProposal
}

const Content: FunctionComponent<ContentProps> = ({ home, proposal }) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()

  const modal = useModal()
  const upButton = useUpButton(home)

  const { product } = useProduct()

  const { scheme } = useProduct()
  const {
    renewal: { sections },
  } = useConfig(getProduct(scheme.schemeCode))

  const quote = getCurrentQuote(proposal)
  const profile = getPolicyholder(quote)
  const asset = getCoreAsset(quote)

  const includedCovers = useIncludedCovers(quote, product)
  const optionalCovers = useOptionalCovers(quote, product)

  const onSubmitExcess = useProposalExcessAdjustment()

  const onOptOut = useNavigateCallback(ProposalPath.OPT_OUT)

  const onAddOnsUpdate = useCallback(
    (e) => {
      e.stopPropagation()
      navigate(ProposalPath.ADD_ONS)
    },
    [navigate],
  )

  const onExcessClick = useCallback(
    (e) => {
      e.stopPropagation()
      modal.show({
        content: (
          <ExcessModalContent
            onSubmitExcess={onSubmitExcess}
            asset={asset}
            initialExcess={asset.voluntaryExcess}
            onClose={modal.hide}
            excessOptions={product.excess.voluntaryExcess ?? []}
          />
        ),
      })
    },
    [asset, modal, onSubmitExcess, product],
  )

  const header = t(tKey('header'), { profile })
  const subheader = (
    <Trans
      t={t}
      i18nKey={tKey('subheader')}
      values={{
        startAt: t(tFormats('datetime.medium'), {
          value: toDateTime(proposal.sourcePolicy.detail.expiredAt),
        }),
      }}
      components={{
        OptOutLink: <LinkAnchor onClick={onOptOut} />,
      }}
    />
  )

  const configuredSections = sections.map((it) => {
    const content = (() => {
      switch (it.type) {
        case ManagementType.ADDITIONAL_PROFILE:
          return <AdditionalProfileManagement config={it} quote={quote} />
        default:
          return <QuoteManagement config={it} quote={quote} />
      }
    })()

    return (
      <Section key={it.id} width={1} mb={xxlBumps}>
        <SectionTitle>{t(tKey('sections', it.id))}</SectionTitle>
        <SectionContent>{content}</SectionContent>
      </Section>
    )
  })

  return (
    <FullPage header={header} subheader={subheader} upButton={upButton}>
      <Section width={1} mb={xxlBumps}>
        <SectionTitle>{t(tKey('sections.updated-terms'))}</SectionTitle>
        <SectionContent>
          <ProposalAdjustmentPricing
            product={product}
            proposal={proposal}
            adjustedQuote={proposal.quote}
            originalQuote={proposal.sourcePolicy.quote}
            width={1}
            p={2}
            variant='raised'
            mb={smBumps}
          />
          <CoverDurationSection proposal={proposal} />
        </SectionContent>
      </Section>

      {configuredSections}

      <Section width={1} mb={xxlBumps}>
        <SectionTitle>{t(tKey('sections.your-cover'))}</SectionTitle>
        <SectionContent>
          <Box variant='raised' p={2}>
            <IncludedCoversExpandable
              covers={includedCovers}
              header={<Typo fontWeight='bold'>{t(tKey('sections.core-covers'))}</Typo>}
            />
            <OptionalCoversExpandable
              covers={optionalCovers}
              header={
                <Flex>
                  <Typo fontWeight='bold'>{t(tKey('sections.optional-covers'))}</Typo>
                  <Flex pl={4}>
                    <LinkAnchor
                      variant='standalone'
                      position='relative'
                      zIndex={1000}
                      typoSize='sm'
                      onClick={onAddOnsUpdate}
                    >
                      {t([tKey('actions.update'), tDefaults('actions.update')])}
                    </LinkAnchor>
                  </Flex>
                </Flex>
              }
            />
            <ExcessExpandable
              quote={quote}
              header={
                <Flex>
                  <Typo fontWeight='bold'>{t(tKey('sections.excesses'))}</Typo>
                  <Flex pl={4}>
                    <LinkAnchor
                      variant='standalone'
                      position='relative'
                      zIndex={1000}
                      typoSize='sm'
                      onClick={onExcessClick}
                    >
                      {t([tKey('actions.update'), tDefaults('actions.update')])}
                    </LinkAnchor>
                  </Flex>
                </Flex>
              }
            />
            <ExclusionsExpandable
              header={<Typo fontWeight='bold'>{t(tKey('sections.exclusions'))}</Typo>}
            />
          </Box>
        </SectionContent>
      </Section>

      <ProductDocumentsSection
        quote={quote}
        header={t<string>(tKey('sections.your-documents'))}
      />
    </FullPage>
  )
}

interface RenewalQuotePageProps {
  home: Page
}

export const RenewalQuotePage: FunctionComponent<RenewalQuotePageProps> = ({ home }) => {
  const { proposal } = useProposal()

  return isRenewalProposal(proposal) ? (
    <Content home={home} proposal={proposal} />
  ) : (
    <Navigate to={AppPath.HOME} />
  )
}
