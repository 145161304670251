import { Form, xxlBumps } from '@wrisk/ui-components'
import { isNil } from 'lodash'
import { DateTime } from 'luxon'
import React, { FunctionComponent, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { isCancellationReasonInvalid } from '../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { Page } from '../../../../infrastructure/routing'
import { CancellationCoolingOffConfig } from '../../../../state/configuration'
import { CancellationReasonInput } from '../../../organisms/cancellation'
import { ActionBar } from '../../../organisms/form'
import { FullPage } from '../../../templates'
import { usePolicy } from '../policyContext'
import { CancellationPath } from './CancellationPath'

export interface CancellationCoolingOffPageProps {
  config: CancellationCoolingOffConfig
  parent: Page
}

const tKey = TKey('pages.cancel')

export const CancellationCoolingOffPage: FunctionComponent<
  CancellationCoolingOffPageProps
> = ({ parent, config }) => {
  const navigate = useNavigate()

  const { t } = useWriskTranslation()

  const { policy } = usePolicy()

  const [cancellationReason, onReasonChange] = useState<string | null>(null)

  const onDismiss = useCallback(() => navigate(parent.url), [navigate, parent])

  const onSubmit = useCallback(() => {
    navigate(CancellationPath.CONFIRM, {
      state: {
        cancellationReason,
        dateTime: DateTime.fromISO(policy.policyDetail.inceptedAt).toISO(),
      },
    })
  }, [cancellationReason, navigate, policy])

  return (
    <FullPage
      pageId='policy-cancellation-page'
      header={t(tKey('header'))}
      subheader={t(tKey('subheader'))}
      helpLinkText={t(tKey('help-link-message'))}
      helpLinkUrl={t<string>('links.cancellationTerms')}
    >
      <Form formId='policy-cancellation-form' onSubmit={onSubmit}>
        {!isNil(config.reasons) && (
          <CancellationReasonInput
            onChange={onReasonChange}
            reasons={config.reasons}
            value={cancellationReason}
            mb={xxlBumps}
          />
        )}

        <ActionBar
          tKey={tKey}
          destructive
          disabled={isCancellationReasonInvalid(config.reasons, cancellationReason)}
          onDismiss={onDismiss}
        />
      </Form>
    </FullPage>
  )
}
