import {
  AdditionalProfileAttributeChange,
  AdditionalProfileDisclosureChange,
  Data,
  getInsuredProfile,
  getPolicyVersion,
  Policy,
  PolicyAdjustmentRequest,
  PolicyChangeType,
  Profile,
} from '../../../../domain'
import {
  AdjustmentInputConfig,
  ChangeType,
  ProfileAttributeChangeConfig,
  ProfileChangeConfig,
} from '../../../../state/configuration'
import { hasUpdate, toUpdates } from '../../helpers'

const toAdditionalProfileDisclosureReducer =
  (data: Data, { profileCode }: Profile) =>
  (
    policyAdjustment: PolicyAdjustmentRequest,
    input: AdjustmentInputConfig<ProfileChangeConfig>,
  ): PolicyAdjustmentRequest => {
    const changes: AdditionalProfileDisclosureChange[] = toUpdates(input, data).map(
      (update) => ({
        changeType: PolicyChangeType.ADDITIONAL_PROFILE_DISCLOSURE_CHANGE,
        profileCode,
        update,
      }),
    )

    return {
      ...policyAdjustment,
      changes: [...policyAdjustment.changes, ...changes],
    }
  }

const toAdditionalProfileAttributeReducer =
  (data: Data, { profileCode }: Profile) =>
  (
    policyAdjustment: PolicyAdjustmentRequest,
    input: AdjustmentInputConfig<ProfileAttributeChangeConfig>,
  ): PolicyAdjustmentRequest => {
    const changes: AdditionalProfileAttributeChange[] = toUpdates(input, data).map(
      (update) => ({
        changeType: PolicyChangeType.ADDITIONAL_PROFILE_ATTRIBUTE_CHANGE,
        profileCode,
        update,
      }),
    )

    return {
      ...policyAdjustment,
      changes: [...policyAdjustment.changes, ...changes],
    }
  }

export const toAdditionalProfilePolicyAdjustment =
  (inputs: AdjustmentInputConfig[], policy: Policy, profile: Profile) =>
  (existingData: Data, data: Data, effectiveAt?: string): PolicyAdjustmentRequest => {
    const { quote } = getPolicyVersion(policy)
    const policyProfileReducer = toAdditionalProfileDisclosureReducer(data, profile)
    const policyProfileAttributeReducer = toAdditionalProfileAttributeReducer(
      data,
      profile,
    )

    return inputs.filter(hasUpdate(existingData, data)).reduce(
      (proposalAdjustment: PolicyAdjustmentRequest, input) => {
        switch (input.adjustment.changeType) {
          case ChangeType.PROFILE_DISCLOSURE:
            return policyProfileReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<ProfileChangeConfig>,
            )
          case ChangeType.PROFILE_ATTRIBUTE:
            return policyProfileAttributeReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<ProfileAttributeChangeConfig>,
            )
          default:
            throw new Error(
              `AdjustmentChangeType ${input.adjustment.changeType} not supported by this`,
            )
        }
      },
      {
        policyId: policy.policyId,
        adjustmentType: 'MTA',
        changes: !getInsuredProfile(quote, profile.profileCode)
          ? [
              {
                changeType: PolicyChangeType.ADDITIONAL_PROFILE_CREATE_CHANGE,
                profileCode: profile.profileCode,
              },
            ]
          : [],
        ...(effectiveAt && { effectiveAt }),
      },
    )
  }
