import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Proposal } from '../../../../../domain'
import { AdditionalProfileDisclosureConfig } from '../../../../../state/configuration'
import {
  AdditionalProfileCreateDisclosure,
  AdditionalProfileDisclosure,
} from './components/AdditionalProfileDisclosure'
import { AdditionalProfileSummary } from './components/AdditionalProfileSummary'
import { AdditionalProfilePath } from './path'

export interface DisclosureFormProps {
  section: AdditionalProfileDisclosureConfig
  onComplete: (proposal: Proposal) => void
  isLastSection: boolean
}

export const AdditionalProfileRouter: FunctionComponent<DisclosureFormProps> = ({
  section,
  isLastSection,
  onComplete,
}) => (
  <Routes>
    <Route
      index
      element={
        <AdditionalProfileSummary
          section={section}
          isLastSection={isLastSection}
          onComplete={onComplete}
        />
      }
    />
    <Route
      path={AdditionalProfilePath.Profile}
      element={
        <AdditionalProfileDisclosure section={section} isLastSection={isLastSection} />
      }
    />
    <Route
      path={AdditionalProfilePath.NewProfile}
      element={
        <AdditionalProfileCreateDisclosure
          section={section}
          isLastSection={isLastSection}
        />
      }
    />
    <Route path='*' element={<Navigate to='..' />} />
  </Routes>
)
