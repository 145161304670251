import React, { FunctionComponent } from 'react'
import { generatePath, Navigate, Route, Routes } from 'react-router-dom'

import { getSchemes, useConfig } from '../state/configuration'
import { AccountRouter } from './account'
import { AppPath } from './AppPath'
import { ProtectedRoutes, usePrincipal } from './authentication'
import { HomePage } from './homePage'
import { ProductRouter } from './product'
import { RetrieveRouter } from './retrieve'

export const AppRouter: FunctionComponent = () => {
  const principal = usePrincipal()

  const schemes = useConfig(getSchemes)

  return (
    <Routes>
      <Route key='home' index element={<HomePage />} />

      {!principal.isAuthenticated && (
        <Route
          key='retrieve'
          path={AppPath.RETRIEVE + AppPath.WILDCARD}
          element={<RetrieveRouter principal={principal} />}
        />
      )}

      {schemes.map((scheme) => (
        <Route
          key={scheme.schemeCode}
          path={generatePath(AppPath.PRODUCT, scheme) + AppPath.WILDCARD}
          element={<ProductRouter scheme={scheme} />}
        />
      ))}

      <Route
        key='account'
        path={AppPath.ACCOUNT + AppPath.WILDCARD}
        element={<AccountRouter />}
      />

      <Route
        key='sign-in'
        path={AppPath.SIGN_IN}
        element={
          <ProtectedRoutes>
            <Navigate to={AppPath.HOME} />
          </ProtectedRoutes>
        }
      />

      <Route key='redirect' path='*' element={<Navigate to={AppPath.HOME} />} />
    </Routes>
  )
}
