import {
  AssetAttributeChange,
  AssetDisclosureChange,
  Data,
  getCoreAsset,
  getCurrentQuote,
  PolicyChangeType,
  ProfileAttributeChange,
  ProfileDisclosureChange,
  Proposal,
  ProposalAdjustmentRequest,
  QuoteAttributeChange,
  QuoteDisclosureChange,
} from '../../../../domain'
import {
  AdjustmentInputConfig,
  AssetAttributeChangeConfig,
  AssetChangeConfig,
  ChangeConfig,
  ChangeType,
  ProfileAttributeChangeConfig,
  ProfileChangeConfig,
  QuoteAttributeChangeConfig,
  QuoteChangeConfig,
  StartDateChangeConfig,
} from '../../../../state/configuration'
import { hasUpdate, toUpdates } from '../../helpers'

type ProposalAdjustmentReducer<T extends ChangeConfig> = (
  proposalAdjustment: ProposalAdjustmentRequest,
  input: AdjustmentInputConfig<T>,
) => ProposalAdjustmentRequest

const toProposalAssetDisclosureReducer =
  (data: Data, proposal: Proposal): ProposalAdjustmentReducer<AssetChangeConfig> =>
  (proposalAdjustment, input) => {
    const quote = getCurrentQuote(proposal)
    const { assetCode } = getCoreAsset(quote)

    const changes: AssetDisclosureChange[] = toUpdates(input, data).map((update) => ({
      changeType: PolicyChangeType.ASSET_DISCLOSURE_CHANGE,
      assetCode,
      update,
    }))

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, ...changes],
    }
  }

const toProposalAssetAttributeReducer =
  (
    data: Data,
    proposal: Proposal,
  ): ProposalAdjustmentReducer<AssetAttributeChangeConfig> =>
  (proposalAdjustment, input) => {
    const quote = getCurrentQuote(proposal)
    const { assetCode } = getCoreAsset(quote)

    const changes: AssetAttributeChange[] = toUpdates(input, data).map((update) => ({
      changeType: PolicyChangeType.ASSET_ATTRIBUTE_CHANGE,
      assetCode,
      update,
    }))

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, ...changes],
    }
  }

const toProposalQuoteDisclosureReducer =
  (data: Data): ProposalAdjustmentReducer<QuoteChangeConfig> =>
  (proposalAdjustment, question) => {
    const changes: QuoteDisclosureChange[] = toUpdates(question, data).map((update) => ({
      changeType: PolicyChangeType.QUOTE_DISCLOSURE_CHANGE,
      update,
    }))

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, ...changes],
    }
  }

const toProposalQuoteAttributeReducer =
  (data: Data): ProposalAdjustmentReducer<QuoteAttributeChangeConfig> =>
  (proposalAdjustment, question) => {
    const changes: QuoteAttributeChange[] = toUpdates(question, data).map((update) => ({
      changeType: PolicyChangeType.QUOTE_ATTRIBUTE_CHANGE,
      update,
    }))

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, ...changes],
    }
  }

const toProfileDisclosureReducer =
  (data: Data): ProposalAdjustmentReducer<ProfileChangeConfig> =>
  (proposalAdjustment, question) => {
    const changes: ProfileDisclosureChange[] = toUpdates(question, data).map(
      (update) => ({
        changeType: PolicyChangeType.PROFILE_DISCLOSURE_CHANGE,
        update,
      }),
    )

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, ...changes],
    }
  }

const toProfileAttributeReducer =
  (data: Data): ProposalAdjustmentReducer<ProfileAttributeChangeConfig> =>
  (proposalAdjustment, question) => {
    const changes: ProfileAttributeChange[] = toUpdates(question, data).map((update) => ({
      changeType: PolicyChangeType.PROFILE_ATTRIBUTE_CHANGE,
      update,
    }))

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, ...changes],
    }
  }

const toStartDateDisclosureReducer =
  (data: Data): ProposalAdjustmentReducer<StartDateChangeConfig> =>
  (proposalAdjustment, input) => ({
    ...proposalAdjustment,
    coverStart: data[input.name],
  })

export const toProposalAdjustment =
  (inputs: AdjustmentInputConfig[], applyRating: boolean, proposal: Proposal) =>
  (existingData: Data, data: Data): ProposalAdjustmentRequest => {
    const profileDisclosureReducer = toProfileDisclosureReducer(data)
    const assetDisclosureReducer = toProposalAssetDisclosureReducer(data, proposal)
    const quoteDisclosureReducer = toProposalQuoteDisclosureReducer(data)
    const profileAttributeReducer = toProfileAttributeReducer(data)
    const assetAttributeReducer = toProposalAssetAttributeReducer(data, proposal)
    const quoteAttributeReducer = toProposalQuoteAttributeReducer(data)

    const disclosureReducer = toStartDateDisclosureReducer(data)

    return inputs.filter(hasUpdate(existingData, data)).reduce(
      (proposalAdjustment: ProposalAdjustmentRequest, input) => {
        switch (input.adjustment.changeType) {
          case ChangeType.PROFILE_DISCLOSURE:
            return profileDisclosureReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<ProfileChangeConfig>,
            )
          case ChangeType.ASSET_DISCLOSURE:
            return assetDisclosureReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<AssetChangeConfig>,
            )
          case ChangeType.QUOTE_DISCLOSURE:
            return quoteDisclosureReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<QuoteChangeConfig>,
            )
          case ChangeType.PROFILE_ATTRIBUTE:
            return profileAttributeReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<ProfileAttributeChangeConfig>,
            )
          case ChangeType.ASSET_ATTRIBUTE:
            return assetAttributeReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<AssetAttributeChangeConfig>,
            )
          case ChangeType.QUOTE_ATTRIBUTE:
            return quoteAttributeReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<QuoteAttributeChangeConfig>,
            )
          case ChangeType.START_DATE_CHANGE:
            return disclosureReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<StartDateChangeConfig>,
            )
          default:
            throw new Error('Invalid adjustment type')
        }
      },
      {
        applyRating,
        changes: [],
      },
    )
  }
