import {
  Box,
  Form,
  mdBumps,
  ModalHeader,
  PrimaryButton,
  RadioButton,
  RadioButtonGrid,
} from '@wrisk/ui-components'
import React from 'react'
import { useAsyncCallback, UseAsyncReturn } from 'react-async-hook'

import { InsuredAsset } from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

interface ExcessModalContentProps {
  asset: InsuredAsset
  onClose: () => void
  onSubmitExcess: UseAsyncReturn<void, [{ excess: number }]>
  excessOptions: number[]
  initialExcess: number
}

const tKey = TKey('components.excess-modal')

export const ExcessModalContent: React.FC<ExcessModalContentProps> = ({
  onClose,
  onSubmitExcess,
  excessOptions,
  initialExcess,
}) => {
  const { t } = useWriskTranslation()

  const onSubmitCallback = useAsyncCallback(async (e) => {
    const newExcess = Number(new FormData(e.currentTarget).get('excess'))
    if (newExcess !== initialExcess) await onSubmitExcess.execute({ excess: newExcess })
    onClose()
  })

  const radioInput = (
    <RadioButtonGrid width={1} columns={4}>
      {excessOptions.map((it) => (
        <RadioButton
          key={it}
          name='excess'
          value={it}
          id={it.toString()}
          defaultChecked={it === initialExcess}
          data-testid={`excess${it}`}
        >
          {t(tFormats('currency.short'), { amount: it })}
        </RadioButton>
      ))}
    </RadioButtonGrid>
  )

  const submitButton = (
    <PrimaryButton
      loading={onSubmitCallback.loading}
      type='submit'
      data-testid='modal-button-save'
      mt={mdBumps}
      width={1}
      layout='fixed'
    >
      {t(tKey('actions.confirm'))}
    </PrimaryButton>
  )

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <Form formId='excess-form' onSubmit={onSubmitCallback.execute}>
        {radioInput}
        {submitButton}
      </Form>
    </Box>
  )
}
