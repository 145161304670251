import { useMemo } from 'react'

import { usePrincipal } from '../../../../application/authentication'
import { useProposal } from '../../../../application/product/proposal/proposalContext'
import { Data, PolicyChangeType, Profile, Proposal } from '../../../../domain'
import { AdjustmentInputConfig } from '../../../../state/configuration'
import { useApiErrorHandlingAsyncCallback } from '../../../auth'
import { useErrorHandlingAsyncCallback } from '../../../errors'
import { hasChanges } from '../helpers'
import { toAdditionalProfileProposalAdjustment } from './adjustments'
import { toAdditionalProfileProposalData } from './data'

export const useAdditionalProfileFormData = (
  inputs: AdjustmentInputConfig[],
  profile: Profile | undefined,
) => {
  return useMemo(() => {
    if (!profile) return {}

    const toData = toAdditionalProfileProposalData(profile)

    return inputs.reduce(
      (data, input) => ({
        ...data,
        [input.name]: toData(input.adjustment),
      }),
      {},
    )
  }, [inputs, profile])
}

export const useRemoveAdditionalProfileProposalAdjustment = (applyRating: boolean) => {
  const { proposal, setProposal } = useProposal()
  const { apiClient } = usePrincipal()

  return useErrorHandlingAsyncCallback(async (profile: Profile) => {
    setProposal(
      await apiClient.updateProposal(proposal.proposalCode, {
        applyRating,
        changes: [
          {
            changeType: PolicyChangeType.REMOVE_PROFILE,
            profileCode: profile.profileCode,
          },
        ],
      }),
    )
  })
}

export const useAdditionalProfileProposalAdjustment = (
  inputs: AdjustmentInputConfig[],
  applyRating: boolean,
  profile: Profile,
  onComplete?: (proposal: Proposal) => void,
) => {
  const { apiClient } = usePrincipal()
  const { proposal, setProposal } = useProposal()

  const existingData = useAdditionalProfileFormData(inputs, profile)

  return useApiErrorHandlingAsyncCallback(async (data: Data) => {
    const proposalAdjustment = toAdditionalProfileProposalAdjustment(
      inputs,
      applyRating,
      proposal,
      profile,
    )(existingData, data)

    const updatedProposal = hasChanges(proposalAdjustment)
      ? await apiClient.updateProposal(proposal.proposalCode, proposalAdjustment)
      : proposal

    setProposal(updatedProposal)
    onComplete?.(updatedProposal)
  })
}
